var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-orders user-page__content"},[_c('div',{staticClass:"user-orders__wrap"},[_c('div',{staticClass:"user-orders__sorts"},_vm._l((_vm.sortTypes),function(sort,i){return _c('button',{key:i,staticClass:"user-orders__sorts-item btn",class:{
          active: JSON.stringify(_vm.activeSort) === JSON.stringify(sort),
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.changeSort(sort)}}},[_vm._v("\n        "+_vm._s(sort.title)+"\n      ")])}),0),_c('div',{staticClass:"user-orders__table-wrap"},[(_vm.orders && _vm.orders.data && _vm.orders.data.length)?_c('table',{staticClass:"user-orders__table table"},[_vm._m(0),_c('tbody',_vm._l((_vm.orders.data),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.created_at)))]),_c('td',[_vm._v("\n              "+_vm._s(_vm._f("formatPrice")(item.sum))+"\n            ")]),_c('td',{class:{
                green: _vm.sortTypes.completed.code.includes(item.status.code),
                red: _vm.sortTypes.canceled.code.includes(item.status.code),
              }},[_vm._v("\n              "+_vm._s(item.status.name)+"\n            ")]),_c('td',[_vm._v(_vm._s(item.filial ? item.filial.title : "-"))]),_vm._m(1,true)])}),0)]):_c('span',[_vm._v("Заказы отсутствуют")])]),_c('PaginationComponent',{attrs:{"page":_vm.page,"first":_vm.first,"list":_vm.orders},on:{"change":_vm.paginate}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("№ Заказа")]),_c('th',[_vm._v("Дата заказа")]),_c('th',[_vm._v("Сумма заказа")]),_c('th',[_vm._v("Состояние")]),_c('th',[_vm._v("Магазин")]),_c('th',[_vm._v("Действие")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('a',{attrs:{"href":"#"}},[_vm._v("Открыть")])])
}]

export { render, staticRenderFns }