<template>
  <div class="user-settings user-page__content">
    <div class="user-settings__form form">
      <UserCardComponent @change="handleImageUpload" />
      <div class="form__row">
        <div class="form__col-4">
          <InputComponent
            v-model.trim="form.surname.value"
            :error="form.surname.error"
            title="Фамилия"
            modified
            required
          />
        </div>
        <div class="form__col-4">
          <InputComponent
            v-model.trim="form.name.value"
            :error="form.name.error"
            title="Имя"
            modified
            required
          />
        </div>
        <div class="form__col-4">
          <InputComponent
            v-model.trim="form.patronymic.value"
            :error="form.patronymic.error"
            title="Отчество"
            modified
          />
        </div>
        <div class="form__col-6">
          <InputComponent
            v-model="form.phone.value"
            :error="form.phone.error"
            title="Телефон"
            mask="+7 (###) ### - ## - ##"
            modified
            required
            is-phone
          />
        </div>
        <div class="form__col-6">
          <InputComponent
            v-model.trim="form.email.value"
            :error="form.email.error"
            title="Email"
            modified
            required
          />
        </div>
      </div>
      <CheckboxComponent :checked="subscription" @change="subscription = !subscription">
        <span> Хочу получать наши Спецпредложения и Акции </span>
      </CheckboxComponent>
    </div>
    <div class="user-page__footer">
      <button @click="profileUpdate" type="button" class="btn btn--md btn--red">
        <template v-if="profileUpdateLoading">Сохранение...</template>
        <template v-else>Сохранить</template>
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import USER_PROFILE_UPDATE from "gql/auth/UserProfileUpdate.graphql";
import USER_PHONE_UPDATE from "gql/auth/UserPhoneUpdate.graphql";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import UserCardComponent from "./UserCardComponent.vue";
import PhoneConfirmModal from "components/modals/components/PhoneConfirmModal.vue";

export default {
  name: "SettingsPage",
  data() {
    return {
      subscription: true,
      profileUpdateLoading: false,
      phoneUpdateLoading: false,
      actualPhone: null,
      form: {
        surname: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        name: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        patronymic: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        head_img: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  mounted() {
    this.setProfileData();
  },
  watch: {
    "$store.state.auth.user"() {
      this.setProfileData();
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    changeNumber() {
      return this.actualPhone !== this.form.phone.value.replace(/[^\d+]/g, "");
    },
  },
  methods: {
    handleImageUpload(event) {
      this.form.head_img.value = event;
    },
    setProfileData() {
      if (this.user) {
        this.form.name.value = this.user.name;
        this.form.surname.value = this.user.surname;
        this.form.patronymic.value = this.user.patronymic;
        this.form.email.value = this.user.email;
        this.form.phone.value = this.user.phone;
        this.actualPhone = this.user.phone;
        this.form.head_img.value = this.user.head_img;
      }
    },
    async profileUpdate() {
      if (!this.profileUpdateLoading) {
        this.resetErrors("form");
        this.profileUpdateLoading = true;
        await this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PROFILE_UPDATE,
            variables: {
              surname: this.form.surname.value || undefined,
              name: this.form.name.value || undefined,
              patronymic: this.form.patronymic.value || undefined,
              email: this.form.email.value || undefined,
              head_img: this.form.head_img.value || undefined,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.UserProfileUpdate) {
              this.$store.state.auth.user = data.UserProfileUpdate.user;
              this.$notify({
                title: "Сохранено",
                text: data.UserProfileUpdate.message,
                duration: 5000,
                speed: 200,
                type: "success",
              });
            }
            this.profileUpdateLoading = false;
            if (this.changeNumber) {
              this.phoneUpdate();
            }
          })
          .catch(({ graphQLErrors }) => {
            this.profileUpdateLoading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    phoneUpdate() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_UPDATE,
            variables: {
              phone: this.form.phone.value.replace(/[^\d+]/g, ""),
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.loading = false;
            //показать модалку с вводом кода
            this.$store.state._modals.push({
              component: PhoneConfirmModal,
              options: {},
            });
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
  components: { UserCardComponent, CheckboxComponent, InputComponent },
};
</script>

<style lang="stylus">
.user-settings {
  grid-gap: 30px !important

  &__form {
    padding 30px
    border-radius var(--big-radius)
    background var(--white)
    grid-gap 20px
    +below(540px) {
      grid-gap 15px
      padding 15px
    }
  }

  &__img {
    display flex
    align-items center
    gap: 15px

    img {
      width: 80px
      height: 80px
    }

    .btn {
      padding 5px 15px
      background var(--gray)
      color var(--dark-light)
      border-radius: var(--small-radius)
    }
  }

  .form__row {
    .input {
      width 100%
    }

    .form__col-4 {
      +below(680px) {
        flex-basis calc(100% - 16px)
      }
    }

    .form__col-6 {
      +below(860px) {
        gap 16px
      }
      +below(680px) {
        flex-basis calc(100% - 16px)
      }
    }
  }
}
</style>
