<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">Подтверждение номера</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <IconComponent name="close-square" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <InputComponent
          v-model="code"
          :error="codeError"
          title="Код"
          mask="####"
          modified
          autofocus
          @enter="phoneConfirm"
        />
        <span>{{ confirmTitle }}</span>
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--main" @click="phoneConfirm">
        <IconComponent v-if="loading" name="loading" />
        <template v-else>Подтвердить</template>
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import USER_PHONE_CONFIRM from "gql/auth/UserPhoneConfirm.graphql";
import { confirmUser } from "@/utils";

// step 0 - Ввод номера телефона
// step 1 - Ввод кода из смс
export default {
  name: "PhoneConfirmModal",
  data() {
    return {
      code: null,
      loading: false,
      error: null,
      codeError: null,
      confirmTitle: "Вам поступит звонок от робота.\n Необходимо ввести 4 последние цифры входящего номера",
    };
  },
  methods: {
    confirmUser,
    phoneConfirm() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_CONFIRM,
            variables: {
              code: parseInt(this.code),
            },
          })
          .then(({ data }) => {
            if (data && data.UserPhoneConfirm) {
              this.confirmUser({
                store: this.$store,
                apollo: this.$apollo.provider.defaultClient,
                data: data.UserPhoneConfirm,
                callback: () => {
                  this.$store.state._modals = [];
                },
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.codeError = "Введен неправильный код подтверждения";
          });
      }
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
.login-modal {
  width 100%
  max-width 382px

  &__inputs {
    width 100%
    display grid
    grid-gap 20px

    .input {
      width 100%

      &__container {
        border-color var(--gray-3-dark)
      }

      &__field {
        font-size: 1.125em
        line-height: 20px;
      }
    }
  }

  &__input {
    display grid
    grid-gap 5px
    width 100%
  }

  &__inputs-title {
    display flex
    align-items center
    gap 8px
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
