<template>
  <div class="user-card">
    <ImgComponent :src="$store.state._env.MEDIA_ENDPOINT + image" v-if="image" />
    <ImgComponent :img="user.head_img" v-else />
    <button @click.prevent="$refs.input.click()" class="btn btn--sm">Изменить</button>
    <input type="file" accept="image/*" ref="input" v-show="false" @input="showCropperModal" />
  </div>
</template>

<script>
import CropImageModal from "components/modals/components/CropImageModal.vue";

export default {
  name: "UserCardComponent",
  data() {
    return {
      image: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    handleCallback(e) {
      this.$refs.input.type = "";
      this.$refs.input.type = "file";
      this.image = e.ImageUploadCrop.path;
      let image = e.ImageUploadCrop;
      delete image.__typename;
      delete image.type;
      this.$emit("change", e.ImageUploadCrop);
    },
    showCropperModal(e) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.$store.state._modals.push({
          component: CropImageModal,
          options: {
            data: {
              imageBase64: reader.result,
              image: e.target.files[0],
            },
            callback: this.handleCallback,
          },
        });
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    },
  },
};
</script>

<style lang="stylus">
.user-card {
  display flex
  align-items center
  gap: 15px

  img {
    width: 80px
    height: 80px
    border-radius 50%
  }

  .btn {
    padding 5px 15px
    background var(--gray)
    color var(--dark-light)
    border-radius: var(--small-radius)
  }
}
</style>
