<template>
  <div class="user-wishlist user-page__content">
    <template v-if="favorites && favorites.length">
      <button @click="deleteAll" class="user-wishlist__clear btn btn--md btn--gray-hollow">
        <IconComponent name="close-circle" />
        <span>Удалить все</span>
      </button>
      <ProductsComponent :products="favorites" />
    </template>
    <span v-else>Избранные товары отсутствуют</span>
  </div>
</template>

<script>
import ProductsComponent from "components/ProductsComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "WishlistPage",
  computed: {
    favorites() {
      return this.$store.state.auth.favorites.filter((item) => item.id).map((item) => item.item);
    },
  },
  methods: {
    /**
     * Удаление всех товаров из избранных
     * @returns {Promise<void>}
     */
    async deleteAll() {
      await this.$store
        .dispatch("FAVORITE_DELETE_ALL", {
          apollo: this.$apollo,
        })
        .then(() => {
          this.$store.dispatch("GET_FAVORITES", {
            apollo: this.$apollo,
          });
        });
    },
  },
  components: { IconComponent, ProductsComponent },
};
</script>

<style lang="stylus">
.user-wishlist {
  &__clear {
    align-self flex-start
    height 46px
    padding 10px
    gap: 10px
  }
}
</style>
