<template>
  <div class="user-page__profile">
    <!--    <div class="user-page__profile-card">-->
    <!--      <img src="/static/images/tmp/bonus_card.png" alt="" />-->
    <!--    </div>-->
    <router-link
      class="user-page__profile-link user-page__profile-orders no-hover"
      :to="{ name: 'user', params: { link: 'orders' } }"
    >
      <span class="user-page__profile-link-title">Ваши заказы</span>
      <div class="user-page__profile-link-wrapper">
        <span>Смотреть</span>
        <IconComponent name="arrow" />
      </div>
      <IconComponent class="user-page__profile-link-icon" name="bag" />
    </router-link>
    <router-link
      class="user-page__profile-link user-page__profile-favorites no-hover"
      :to="{ name: 'user', params: { link: 'wishlist' } }"
    >
      <span class="user-page__profile-link-title">Избранное</span>
      <div class="user-page__profile-link-wrapper">
        <span>Смотреть</span>
        <IconComponent name="arrow" />
      </div>
      <IconComponent class="user-page__profile-link-icon" name="heart" />
    </router-link>
  </div>
</template>

<script>
import LinkMoreComponent from "components/LinkMoreComponent.vue";
import IconComponent from "components/IconComponent.vue";
export default {
  name: "ProfilePage",
  components: { IconComponent, LinkMoreComponent },
};
</script>

<style lang="stylus">
.user-page {
  &__profile {
    display grid
    grid-template-columns 1fr 1fr
    //grid-template-columns repeat(3, 1fr)
    grid-gap var(--gap)
    //grid-template-areas "a a b" "a a c"
    //+below(1350px) {
    //  grid-template-columns 1fr 1fr
    //  grid-template-areas "a a" "b c"
    //}
    +below(1025px) {
      grid-template-columns 1fr
      //grid-template-areas "a" "b" "c"
      grid-gap 20px
    }

    &-card {
      width 100%
      height 100%
      grid-area a

      img {
        height 100%
        width 100%
        object-fit contain
      }
    }

    &-link {
      position relative
      background: var(--white);
      border-radius: var(--big-radius);
      padding 30px
      display flex
      flex-direction column
      justify-content space-between
      gap: 20px
      overflow hidden
      +below(440px) {
        height 200px
        padding 30px 15px
      }

      &::after {
        content ""
        box-sizing border-box
        position absolute
        width 250px
        height: 250px
        border-radius 50%
        right 0
        bottom 0
        transform translate(40%, 45%)
      }

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--dark-light);
        +below(440px) {
          font-size 0.875em
        }

        .icon {
          width 32px
          max-width 32px
          height auto
        }
      }

      &-title {
        font-weight: 700;
        font-size: 1.125em
        line-height: 28px;
      }

      &-icon {
        position absolute
        right 30px
        bottom 30px
        width: 50px
        height: 50px
        z-index 2

        svg path {
          fill var(--white)
        }
      }
    }

    &-orders {
      //grid-area b

      &::after {
        background: var(--blue);
        border: 20px solid var(--blue-light)
      }
    }

    &-favorites {
      //grid-area c

      &::after {
        background: var(--yellow);
        border: 20px solid var(--yellow-o15)
      }
    }
  }
}
</style>
