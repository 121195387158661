<template>
  <div class="default-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <span class="default-menu__title">Навигация</span>
        <button type="button" class="default-menu__close" @click="$emit('close')">
          <VuesaxIconComponent name="close" />
        </button>
      </div>
    </div>
    <div class="default-menu__body">
      <ul class="default-menu__links">
        <li v-for="(item, i) in list" :key="i">
          <router-link :to="item.route" class="default-menu__link">
            <span>{{ item.title }}</span>
            <VuesaxIconComponent name="arrow-right-1" />
          </router-link>
        </li>
        <li>
          <a class="default-menu__link" @click.prevent="logout" href="#">Выйти</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VuesaxIconComponent from "components/VuesaxIconComponent.vue";

export default {
  name: "UserMenu",
  data() {
    return {
      list: [
        {
          title: "Регистрационные данные",
          route: { name: "user", params: { link: "profile" } },
        },
        {
          title: "Адрес доставки",
          route: { name: "user", params: { link: "address" } },
        },
        {
          title: "История покупок",
          route: { name: "user", params: { link: "orders" } },
        },
        {
          title: "Мой список желаний",
          route: { name: "user", params: { link: "wishlist" } },
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.state.apollo_token = undefined;
      this.$store.state.auth.user = null;
      if (this.$route.name === "home") {
        location.reload();
        return;
      }
      this.$router.push({ name: "home" }).then(() => {
        location.reload();
      });
    },
  },
  components: { VuesaxIconComponent },
};
</script>
