<template>
  <div class="user-orders user-page__content">
    <!--        <div class="user-orders__points">-->
    <!--          <span class="user-orders__points-title">У вас баллов</span>-->
    <!--          <span class="user-orders__points-value">1000</span>-->
    <!--        </div>-->
    <!--    <div class="user-orders__points">-->
    <!--      <span class="user-orders__points-title">Всего заказов на суммму</span>-->
    <!--      <span class="user-orders__points-value">{{ getOrdersSum | formatPrice }}</span>-->
    <!--    </div>-->
    <div class="user-orders__wrap">
      <div class="user-orders__sorts">
        <button
          class="user-orders__sorts-item btn"
          type="button"
          v-for="(sort, i) in sortTypes"
          :key="i"
          @click="changeSort(sort)"
          :class="{
            active: JSON.stringify(activeSort) === JSON.stringify(sort),
          }"
        >
          {{ sort.title }}
        </button>
      </div>
      <div class="user-orders__table-wrap">
        <table v-if="orders && orders.data && orders.data.length" class="user-orders__table table">
          <thead>
            <tr>
              <th>№ Заказа</th>
              <th>Дата заказа</th>
              <th>Сумма заказа</th>
              <th>Состояние</th>
              <th>Магазин</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in orders.data" :key="i">
              <td>{{ item.id }}</td>
              <td>{{ item.created_at | formatDateTime }}</td>
              <td>
                {{ item.sum | formatPrice }}
              </td>
              <td
                :class="{
                  green: sortTypes.completed.code.includes(item.status.code),
                  red: sortTypes.canceled.code.includes(item.status.code),
                }"
              >
                {{ item.status.name }}
              </td>
              <td>{{ item.filial ? item.filial.title : "-" }}</td>
              <td><a href="#">Открыть</a></td>
            </tr>
          </tbody>
        </table>
        <span v-else>Заказы отсутствуют</span>
      </div>
      <PaginationComponent :page="page" :first="first" :list="orders" @change="paginate" />
    </div>
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";

export default {
  name: "OrdersPage",
  components: { PaginationComponent },
  props: {
    first: Number,
  },
  data() {
    return {
      page: 1,
      loading: false,
      paginateLoading: false,
      activeSort: undefined,
    };
  },
  created() {
    this.activeSort = this.sortTypes.all;
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    orders() {
      return this.$store.state.auth.orders_paginate;
    },
    addresses() {
      return this.$store.state.auth.addresses;
    },
    getOrdersSum() {
      return this.$store.state.auth.orders.map((r) => parseInt(r.sum)).reduce((a, b) => a + b, 0);
    },
    statuses() {
      return this.$store.state._status;
    },
    sortTypes() {
      return {
        all: {
          code: [],
          title: "Все",
        },
        active: {
          code: [
            this.statuses.CODE_ORDER_CREATED,
            this.statuses.CODE_ORDER_SUCCESS,
            this.statuses.CODE_ORDER_IN_PROCESS,
          ],
          title: "Активные",
        },
        canceled: {
          code: [this.statuses.CODE_ORDER_CANCELED, this.statuses.CODE_ORDER_FAILED],
          title: "Отмененные",
        },
        completed: {
          code: [
            this.statuses.CODE_ORDER_IN_DELIVERY,
            this.statuses.CODE_ORDER_DELIVERED,
            this.statuses.CODE_ORDER_OFFLINE_PAY,
          ],
          title: "Выполненные",
        },
      };
    },
  },
  methods: {
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.reload(page);
      }
    },
    async changeSort(sort) {
      this.page = 1;
      this.activeSort = sort;
      await this.reload();
    },
    stopLoading() {
      this.loading = false;
      this.paginateLoading = false;
    },
    reload(page) {
      this.$emit("reload", this.$apollo.provider, this.$store, this.$route, {
        status: this.activeSort.code,
        page: page || 1,
      });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/table.styl"
.user-orders {
  &__points {
    display flex
    align-items center
    justify-content space-between
    gap: 20px
    padding 20px 30px
    border-radius var(--big-radius)
    background: var(--white)
    font-size: 1.75em
    line-height: 38px;
    text-transform: uppercase;
    font-weight 700
    +below(480px) {
      flex-direction column
      align-items flex-start
      gap: 15px
      font-size: 1.375em
    }

    &-value {
      background: var(--blue)
      border-radius: var(--main-radius)
      padding 10px 15px
      color var(--white)
    }
  }

  &__wrap {
    display flex
    flex-direction column
    gap: 20px
  }

  &__sorts {
    background: var(--white);
    border-radius: var(--big-radius)
    padding 10px
    display flex
    overflow auto
    +below(460px) {
      flex-direction column
      gap: 10px
    }

    &-item {
      flex-shrink 0
      font-weight: 700;
      padding 12px 30px
      border-radius: 10px;
      transition var(--transition)
      font-size 1em

      &:hover {
        background var(--gray)
      }

      &.active {
        color var(--blue)
        background var(--gray)
      }
    }
  }

  &__table {
    &-wrap {
      width 100%
      overflow auto
    }
  }
}
</style>
