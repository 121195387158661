<template>
  <div class="user-page-aside">
    <div class="user-page-aside__header">
      <ImgComponent class="user-page-aside__header-img" :img="user.head_img" />
      <div class="user-page-aside__header-info">
        <span class="user-page-aside__header-title"> {{ getUserFio }} </span>
        <span class="user-page-aside__header-email"> {{ user.email }} </span>
      </div>
    </div>
    <ul class="user-page-aside__list">
      <li v-for="(page, i) in pages" :key="i">
        <router-link
          :to="page.route"
          :class="{ 'user-page-aside__link--active': JSON.stringify(value) === JSON.stringify(page) }"
          class="user-page-aside__link no-hover"
        >
          <IconComponent :name="page.icon" />
          <span>{{ page.title }}</span>
        </router-link>
      </li>
      <li>
        <a
          @click.prevent="logout({ store: $store, router: $router })"
          class="user-page-aside__link no-hover"
          href="#"
        >
          <IconComponent name="login" />
          <span>Выход</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import { logout } from "@/utils";

export default {
  name: "UserPageAsideComponent",
  components: { IconComponent },
  props: {
    pages: Array,
    value: Object,
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    getUserFio() {
      return [this.user?.surname, this.user?.name].filter((a) => a).join(" ");
    },
  },
  methods: {
    logout,
  },
};
</script>

<style lang="stylus">
.user-page-aside {
  display grid
  border-radius: var(--big-radius)
  background var(--white)

  &__header {
    padding 10px 15px
    border-bottom: 1px solid var(--gray);
    display flex
    align-items center
    gap: 15px

    &-img {
      width 80px
      height 80px
      flex-shrink 0
      border-radius 50%
    }

    &-info {
      display flex
      flex-direction column
      gap: 5px
    }

    &-email {
      color: var(--dark-light);
      word-break break-word
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.125em
    line-height: 140%;
  }

  &__list {
    display grid
  }

  &__link {
    padding 10px 15px
    gap 8px
    display flex
    align-items center

    &:hover {
      background var(--gray)
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--dark)
      }
    }

    &--active {
      color var(--blue)

      .icon svg path {
        fill var(--blue)
      }
    }
  }
}
</style>
